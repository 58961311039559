<template>
  <div class="why-sell-section">
    <h3 class="head">Why sell with AutoFlip?</h3>
    <div class="d-flex justify-content-center p-4">
      <div v-for="item of items" :key="item.id" class="why-sell-item">
        <img
          :src="
            require(`@/assets/images/icons/reason-info/ic_${item.path}.png`)
          "
        />
        <p>{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhySell",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.why-sell-section {
  padding: 20px;
  background-color: #f9fafb;
  margin-bottom: 25px;

  .head {
    text-align: center;
    font-size: 22px;
  }

  .why-sell-item {
    margin: 5px;
    width: 100%;
    min-width: 100px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      margin-top: 10px;
    }

    p {
      font-size: 13px;
    }
  }
}

@media screen and (min-width: 768px) {
  .why-sell-item {
    margin: 10px !important;

    p {
      font-size: inherit !important;
    }
  }
}
</style>